<template>
  <v-card class="new-exrate-form" data-test-id="exrate">
    <v-toolbar flat>
      <v-toolbar-title>{{
        !modeCreate ? "Update Exchange rate" : "Create Exchange rate"
      }}</v-toolbar-title>
      <v-divider vertical inset class="mx-4"></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        @click="updateExRate"
        :loading="runningAction"
        :disabled="runningAction"
        color="green"
        data-test-id="createExRateBtn"
      >
        Save
      </v-btn>
      <v-btn
        @click="closeDialog()"
        text
        class="close-dialog-btn"
        data-test-id="closeExRateDialogBtn"
      >
        Cancel
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-form @submit.prevent ref="exrateForm">
        <v-text-field
          dense
          outlined
          v-model="exrate.name"
          label="Currency*"
          class="exrate-namme"
          :rules="inputRules"
          data-test-id="exrateName"
          :disabled="disabled || runningAction"
        />

        <v-text-field
          dense
          outlined
          type="number"
          v-model="exrate.rate"
          label="Rate*"
          class="exrate-rate"
          :rules="inputRules"
          data-test-id="exrateRate"
          placeholder="1.5"
          :disabled="disabled || runningAction"
        />
      </v-form>
    </v-container>
  </v-card>
</template>


<script>
export default {
  name: "ExRate",

  props: {
    inputExRate: {
      required: false,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    reseller: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      exrate: !this.modeCreate ? this.$cloneObject(this.inputExRate) : {},
      runningAction: false,
      inputRules: [(v) => !!v || "Value is required"],
    };
  },

  watch: {
    "inputExRate.id": function () {
      //another exrate has been selected
      this.exrate = this.inputExRate;
    },
  },

  computed: {
    modeCreate() {
      return !this.inputExRate || !this.inputExRate.id;
    },
  },

  methods: {
    async updateExRate() {
      if (this.$refs.exrateForm.validate()) {
        this.runningAction = true;
        try {
          let res;
          if (!this.modeCreate) {
            res = await this.$store.$b2bApi.b2bResellerApi.upsertExRate(
              this.reseller.domain,
              this.reseller.id,
              this.exrate
            );
          } else {
            if (!this.exrate.id) {
              this.exrate.id = this.$uuid.v4();
            }
            res = await this.$store.$b2bApi.b2bResellerApi.upsertExRate(
              this.reseller.domain,
              this.reseller.id,
              this.exrate
            );
          }
          if (!res?.ok) return;
          this.closeDialog(true);
        } finally {
          this.runningAction = false;
        }
      }
    },

    closeDialog(reload) {
      this.createExRate = false;
      this.exrate = {};
      this.$emit("close", reload);
    },
  },
};
</script>

<style scoped>
</style>