<template>
  <DialogForm
    :loading="runningAction"
    :title="title"
    :save-btn-label="saveBtnLabel"
    close-btn-label="Cancel"
    save-btn-test-id="createUserBtn"
    close-btn-test-id="closeUserDialogBtn"
    class="new-user-form"
    data-test-id="user"
    @close="$emit('close', false)"
    @save="upsertUser"
  >
    <template #form="{ loading }">
      <v-container>
        <v-list two-line v-if="!modeCreate">
          <v-list-item>
            <v-list-item-icon><v-icon>mdi-web</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title data-test-id="userCustomerId">
                <a
                  :href="
                    $router.resolve({
                      name: 'customerDetail',
                      params: {
                        domain: reseller.domain,
                        customer: user.id,
                      },
                    }).href
                  "
                  target="_blank"
                >
                  {{ user.id }}
                </a>
              </v-list-item-title>
              <v-list-item-subtitle>Customer-ID</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-form @submit.prevent ref="userForm">
          <v-text-field
            dense
            outlined
            v-model="user.firstname"
            label="Firstname*"
            class="create-user-firstname"
            :rules="[ruleSet.required]"
            data-test-id="userFirstname"
            :disabled="disabled || loading"
          />

          <v-text-field
            dense
            outlined
            v-model="user.lastname"
            label="Lastname*"
            class="create-user-lastname"
            :rules="[ruleSet.required]"
            data-test-id="userLastname"
            :disabled="disabled || loading"
          />
          <v-text-field
            dense
            outlined
            v-model="user.username"
            label="Username*"
            class="create-user-username"
            :rules="[ruleSet.required]"
            data-test-id="userUserName"
            :disabled="disabled || loading"
          />
          <v-text-field
            dense
            outlined
            v-model="user.email"
            label="Email*"
            class="create-user-email"
            :rules="[ruleSet.required, ruleSet.email]"
            data-test-id="userEmail"
            :disabled="disabled || loading"
          />

          <v-text-field
            dense
            outlined
            v-model="user.password"
            :label="!!modeCreate ? 'Password*' : 'Password'"
            class="create-user-password"
            :rules="[ruleSet.password]"
            data-test-id="userPassword"
            :disabled="disabled || loading"
          />

          <v-select
            dense
            outlined
            v-model="user.locale"
            :items="availableLocales"
            label="Locale*"
            class="create-user-sps"
            item-text="text"
            item-value="value"
            data-test-id="userLocale"
            :rules="[ruleSet.required]"
            :disabled="disabled || loading"
          />

          <v-select
            dense
            outlined
            v-model="user.roles"
            :items="roles"
            multiple
            label="Roles*"
            class="create-user-sps"
            data-test-id="userRole"
            :rules="[ruleSet.required]"
            :disabled="disabled || loading"
          />
        </v-form>

        <v-checkbox
          v-if="!modeCreate"
          class="mt-0"
          v-model="user.deleted"
          label="Deleted"
          dense
          outlined
          disabled
          hide-details="auto"
          data-test-id="userDeleted"
        />

        <v-checkbox
          v-if="!modeCreate"
          v-model="user.changePwd"
          label="Change password"
          dense
          outlined
          data-test-id="userChangePwd"
          :disabled="loading"
        />

        <v-text-field
          v-if="!modeCreate"
          dense
          outlined
          disabled
          v-model="user.changeDatePwd"
          label="Change password date"
          class="create-user-change-date-pwd"
          data-test-id="userChangeDatePwd"
        />
      </v-container>
    </template>
  </DialogForm>
</template>


<script>
import DialogForm from "../../common/templates/DialogForm";
import resellerMixin from "../../../mixins/reseller-mixin";
import inputRules from "../../../mixins/field-rule-validation";

export default {
  name: "User",
  mixins: [resellerMixin, inputRules],
  components: {
    DialogForm,
  },
  props: {
    inputUser: {
      required: false,
      default: null,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    reseller: {
      required: true,
    },
  },

  data() {
    return {
      user: !this.modeCreate ? this.$cloneObject(this.inputUser) : {},
      runningAction: false,
    };
  },

  watch: {
    "inputUser.id": function () {
      //another user has been selected
      this.user = this.inputUser;
    },
  },

  methods: {
    async upsertUser() {
      const form = this.$refs.userForm;
      if (this.$validateVForm(form)) {
        try {
          let res;
          this.runningAction = true;
          if (!this.modeCreate) {
            res = await this.$store.$b2bApi.b2bResellerApi.updateUser(
              this.reseller.domain,
              this.reseller.id,
              this.user
            );
          } else {
            res = await this.$store.$b2bApi.b2bResellerApi.createUser(
              this.reseller.domain,
              this.reseller.id,
              this.user
            );
          }
          if (!res?.ok) return;

          this.closeDialog(true);
        } finally {
          this.runningAction = false;
        }
      }
    },

    closeDialog(reload) {
      this.createUser = false;
      this.user = {};
      this.$emit("close", reload);
    },
  },

  computed: {
    modeCreate() {
      return !this.inputUser || !this.inputUser.id;
    },

    title() {
      return this.modeCreate ? "Create User" : "Update User";
    },

    saveBtnLabel() {
      return this.modeCreate ? "Create" : "Save";
    },
  },
};
</script>

<style scoped>
</style>