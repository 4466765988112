<template>
  <DialogForm
    :flat="!modeCreate"
    :loading="runningAction"
    :title="title"
    data-test-id="reseller"
  >
    <template #actions="{ loading }">
      <v-btn
        v-if="editEnabled || modeCreate"
        color="green"
        data-test-id="createResellerBtn"
        class="mr-2"
        :disabled="resellerInputsDisabled || loading"
        @click="upsertReseller"
      >
        {{ modeCreate ? "Create" : "Save" }}
      </v-btn>

      <v-btn
        v-if="deleteRestoreVisible"
        @click="deleteRestoreReseller"
        :disabled="loading"
        :color="reseller.deleted ? 'blue' : 'red'"
        class="reseller-editor-delete-btn reseller-editor-toolbar-btn mr-2"
        data-test-id="resellerDetailDeleteBtn"
      >
        {{ reseller.deleted ? "Restore" : "Delete" }}
      </v-btn>

      <v-btn
        @click="edit(!editEnabled)"
        v-if="!reseller.deleted && !modeCreate"
        :disabled="loading"
        color="primary"
        class="reseller-editor-edit-btn reseller-editor-toolbar-btn"
        data-test-id="resellerDetailEditBtn"
      >
        {{ editEnabled ? "Cancel" : "Edit" }}
      </v-btn>

      <v-btn
        v-if="modeCreate"
        text
        data-test-id="closeResellerDialogBtn"
        :disabled="loading"
        @click="closeDialog()"
      >
        Cancel
      </v-btn>
    </template>

    <template #form="{ loading }">
      <v-container fluid class="pa-0">
        <v-container fluid v-if="!modeCreate">
          <v-list two-line>
            <v-list-item>
              <v-list-item-icon
                ><v-icon>mdi-file-tree</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title> {{ reseller.domain }} </v-list-item-title>
                <v-list-item-subtitle>Domain</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon><v-icon>mdi-web</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title data-test-id="resellerCustomerId">
                  <a
                    :href="
                      $router.resolve({
                        name: 'customerDetail',
                        params: {
                          domain: reseller.domain,
                          customer: reseller.id,
                        },
                      }).href
                    "
                    target="_blank"
                  >
                    {{ reseller.id }}
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle>Customer-ID</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-alert
            v-if="showErpIdHint"
            class="pl-3"
            border="left"
            type="warning"
            data-test-id="erpIdHint"
          >
            Reseller does not have an ERP ID
          </v-alert>
        </v-container>

        <v-form @submit.prevent ref="resellerForm">
          <div class="pa-2">
            <v-subheader
              data-test-id="createResellerHeader"
              class="subtitle"
              v-if="modeCreate"
              >Reseller</v-subheader
            >
            <v-text-field
              v-model="reseller.name"
              dense
              outlined
              label="Reseller name*"
              class="create-reseller-name"
              data-test-id="newResellerName"
              :rules="[ruleSet.required]"
              :disabled="loading || resellerInputsDisabled"
              @blur="handleResellerNameChange($event)"
            />

            <v-text-field
              v-model="reseller.email"
              dense
              outlined
              label="Reseller email*"
              class="create-reseller-email"
              placeholder="example@mail.com"
              data-test-id="newResellerEmail"
              :rules="[ruleSet.required, ruleSet.email]"
              :disabled="loading || resellerInputsDisabled"
            />

            <v-select
              v-model="reseller.locale"
              dense
              outlined
              label="Reseller locale*"
              class="create-reseller-sps"
              item-text="text"
              item-value="value"
              data-test-id="newResellerLocale"
              :disabled="loading || resellerInputsDisabled"
              :rules="[ruleSet.required]"
              :items="availableLocales"
            />

            <v-text-field
              v-model="reseller.erpId"
              dense
              outlined
              label="Reseller ERP ID"
              class="create-reseller-erp-id"
              data-test-id="newResellerErpId"
              hide-details
              :disabled="loading || resellerInputsDisabled"
            />

            <v-checkbox
              v-if="!modeCreate"
              v-model="reseller.deleted"
              label="Deleted"
              dense
              outlined
              disabled
              hide-details="true"
              data-test-id="isResellerDeleted"
            />
          </div>

          <div v-if="modeCreate" outlined data-test-id="resellerDomain">
            <v-subheader class="subtitle">Domain</v-subheader>
            <v-checkbox
              v-model="isNewDomain"
              class="ml-1 mt-0"
              label="Create a new domain for reseller"
              dense
              outlined
              hide-details="auto"
              data-test-id="isCreateNewDomainForReseller"
              :disabled="loading"
            />

            <DomainSelector
              v-if="!isNewDomain"
              v-model="resellerDomainSelected"
              class="mt-4 ml-2"
              label="Select domain..."
              :outlined="true"
              :disable-domain-creation="true"
              :set-local="true"
              :rules="[ruleSet.domain]"
              :hide-details="false"
              :disabled="loading"
              only-main-domain
            />

            <Domain
              v-else
              v-model="resellerDomainNew"
              flat
              class="pl-0"
              external-domain-creation
              ref="upsertdomain"
              :parent="selectedDomain"
              :disabled="loading"
              @reload="closeDialog(true)"
              @close="closeDialog(false)"
            />
          </div>
        </v-form>
      </v-container>
    </template>
  </DialogForm>
</template>

<script>
import DialogForm from "components/common/templates/DialogForm";
import DomainSelector from "components/domain/DomainSelector";
import Domain from "components/domain/Domain";
import resellerMixin from "mixins/reseller-mixin";
import inputRules from "mixins/field-rule-validation";

export default {
  name: "Reseller",
  mixins: [resellerMixin, inputRules],
  props: {
    value: {
      required: false,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  components: {
    DomainSelector,
    Domain,
    DialogForm,
  },

  data() {
    return {
      reseller: this.value ? this.$cloneObject(this.value) : {},
      resellerDomainSelected: {},
      resellerDomainNew: {},
      parentDomainOfSubDomain: {},
      runningAction: false,
      isNewDomain: true,
      editEnabled: false,
      locale: "",
    };
  },
  async mounted() {
    window.addEventListener("click", function () {
      if (this.contextMenuShown) this.parentDomain = null;
      this.contextMenuShown = false;
    });
  },
  watch: {
    reseller: {
      deep: true,
      handler: function (reseller) {
        this.$emit("input", reseller);
      },
    },
    value: {
      deep: true,
      handler: function (value) {
        if (value?.id !== this.reseller?.id) {
          //another reseller has been selected/refreshed
          this.reseller = this.$cloneObject(value);
        }
      },
    },
  },
  methods: {
    async upsertReseller() {
      const form = this.$refs.resellerForm;
      if (this.$validateVForm(form)) {
        this.runningAction = true;
        try {
          let res = {};
          if (!this.modeCreate) {
            res = await this.$store.$b2bApi.b2bResellerApi.upsertReseller(
              this.reseller
            );
            if (!res?.ok) return;
            this.editEnabled = false;
            this.$emit("refresh");
          } else {
            let domainID = "";
            if (this.isNewDomain) {
              await this.$refs.upsertdomain.upsertDomain();
              await this.$store.dispatch("loadDomains");
              domainID = this.resellerDomainNew.id;
            } else {
              domainID = this.resellerDomainSelected.id;
            }
            res = await this.$store.$b2bApi.b2bResellerApi.createReseller(
              domainID,
              this.reseller
            );
            if (!res?.ok) return;
            this.editEnabled = false;
            this.closeDialog(true);
          }
        } finally {
          this.runningAction = false;
        }
      }
    },

    async deleteRestoreReseller() {
      this.runningAction = true;
      if (!this.reseller.deleted) {
        if (
          await this.$confirm(
            "Delete reseller?",
            "Are you sure you want to delete reseller " +
              this.reseller.name +
              " (" +
              this.reseller.id +
              ")" +
              "?"
          )
        ) {
          this.reseller.deleted = true;
          await this.$store.$b2bApi.b2bResellerApi.upsertReseller(
            this.reseller
          );
          this.editEnabled = false;
        }
      } else {
        this.reseller.deleted = false;
        await this.$store.$b2bApi.b2bResellerApi.upsertReseller(this.reseller);
      }
      this.$emit("refresh");
      this.runningAction = false;
    },

    async loadReseller() {
      this.runningAction = true;
      try {
        this.reseller = await this.$store.$b2bApi.b2bResellerApi.loadReseller(
          this.reseller.domain,
          this.reseller.id
        );

        if (!this.reseller) {
          this.closeEditor(true);
          return;
        }
      } finally {
        this.runningAction = false;
      }
    },

    edit(enabled) {
      this.loadReseller();
      this.editEnabled = enabled;
    },

    closeEditor(reload) {
      this.$emit("close", reload);
    },

    closeDialog(reload) {
      this.createReseller = false;
      this.resellerDomainSelected = {};
      this.resellerDomainNew = {};
      this.reseller = {};
      this.$emit("close", reload);
    },

    handleResellerNameChange(event) {
      if (!this.resellerDomainNew.name || this.resellerDomainNew.name === "") {
        this.$set(this.resellerDomainNew, "name", event.target.value);
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    modeCreate() {
      return !this.value?.id;
    },

    title() {
      return !this.modeCreate ? "Reseller Information" : "Create Reseller";
    },

    resellerInputsDisabled() {
      return (
        !this.modeCreate &&
        (this.disabled || this.runningAction || !this.editEnabled)
      );
    },

    deleteRestoreVisible() {
      return (this.editEnabled || this.reseller.deleted) && !this.modeCreate;
    },

    showErpIdHint() {
      return !this.value?.erpId;
    },
  },
};
</script>

<style scoped>
.subtitle {
  font-size: 1.2em;
}
</style>