<template>
  <v-container
    fluid
    fill-height
    class="reseller-exrate-container"
    data-test-id="ExRatesList"
  >
    <v-card flat class="reseller-exrates" id="exrates">
      <v-card-title>
        <v-icon left>mdi-cash-multiple</v-icon>
        <span>Exchange Rate</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          data-test-id="addExRateBtn"
          :disabled="disabled || loadingDelete"
          @click="upsertExRate()"
        >
          New Exchange Rate
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        sort-by="preferred"
        :sort-desc="true"
        :items="exrates"
        :loading="runningAction"
        :items-per-page="5"
        :headers="exrateHeaders"
        class="reseller-exrates-table"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-container class="action-btn-container">
            <v-btn
              small
              icon
              @click="upsertExRate(item)"
              :disabled="disabled || loadingDelete"
              :data-test-id="'exrate_' + item.id + '_editBtn'"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              @click="deleteExRate(item)"
              :loading="loadingDelete"
              :disabled="disabled || loadingDelete"
              :data-test-id="'exrate_' + item.id + '_deleteBtn'"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
    <!-- CREATE EXCHANGE DIALOG -->
    <v-dialog
      persistent
      v-model="exrateDialog"
      width="500px"
      v-if="exrateDialog"
    >
      <ExRate
        :reseller="reseller"
        :input-ex-rate="selectedExRate"
        @close="closeExRateDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ExRate from "./ExRate";

export default {
  props: {
    reseller: {
      required: false,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  components: {
    ExRate,
  },

  data() {
    return {
      selectedExRate: {},
      exrateDialog: false,
      exrates: [],
      loading: false,
      inputRules: [(v) => !!v || "Value is required"],
      loadingDelete: false,
      runningAction: false,
    };
  },

  async mounted() {
    await this.loadExRates();
  },

  watch: {
    "reseller.id": function () {
      //another reseller has been selected
      this.loadExRates();
    },
  },

  methods: {
    async loadExRates() {
      if (this.reseller.id) {
        this.runningAction = true;
        //load the resellers exrates and categorize it into billing and shipping exrate
        this.exrates = await this.$store.$b2bApi.b2bResellerApi.getExRates(
          this.reseller.domain,
          this.reseller.id
        );
        this.runningAction = false;
      }
    },

    upsertExRate(exrate) {
      this.exrateDialog = true;
      if (exrate) this.selectedExRate = exrate;
      else this.selectedExRate = {};
    },

    async deleteExRate(exrate) {
      if (
        await this.$confirm(
          "Delete exrate?",
          "Are you sure you want to delete exchange rate " +
            exrate.name +
            " (" +
            exrate.rate +
            ")" +
            "?"
        )
      ) {
        this.loadingDelete = true;
        try {
          await this.$store.$b2bApi.b2bResellerApi.deleteExRate(
            this.reseller.domain,
            this.reseller.id,
            exrate
          );
        } finally {
          this.loadingDelete = false;
          this.closeExRateDialog(true);
        }
      }
    },

    closeExRateDialog(reload) {
      this.exrateDialog = false;
      this.selectedExRate = {};
      if (reload) this.loadExRates();
    },
  },

  computed: {
    exrateHeaders() {
      return [
        { text: "Currency", value: "name" },
        { text: "Rate", value: "rate" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.action-btn-container {
  padding: 0;
  display: flex;
}

.reseller-exrates {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
}
</style>