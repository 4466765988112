import ResellerEnum from "./enum/ResellerEnum"
export default {
    mixins: [ResellerEnum],
    computed: {
        supportedLocales() {
            return this.$store.state.localization.supportedLocales;
        },

        availableLocales() {
            const locales = this.b2bLocales;
            return locales.filter(({ value }) => {
                return this.supportedLocales.some((locale) => locale === value);
            });
        },
    }

}