<template>
  <v-container
    fluid
    fill-height
    class="reseller-user-container"
    data-test-id="UsersList"
  >
    <v-card flat class="reseller-users" id="users">
      <v-card-title>
        <v-icon left>mdi-account-group</v-icon>
        <span>Users</span>
        <v-spacer></v-spacer>
        <v-switch
          data-test-id="userDeletedSwitch"
          v-model="showDeleted"
          label="Show deleted"
          color="primary"
          :disabled="runningAction"
          @change="loadUsers"
        ></v-switch>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn
          color="primary"
          data-test-id="addUserBtn"
          :disabled="disabled || runningAction"
          @click="upsertUser()"
        >
          New User
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        sort-by="preferred"
        :sort-desc="true"
        :items="users"
        :items-per-page="5"
        :loading="runningAction"
        :headers="userHeaders"
        class="reseller-users-table"
        :item-class="(item) => (item.deleted ? 'deleted' : '')"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.id="{ item }">
          <v-container class="action-btn-container">
            <a
              :href="
                $router.resolve({
                  name: 'customerDetail',
                  params: {
                    domain: reseller.domain,
                    customer: item.id,
                  },
                }).href
              "
              target="_blank"
            >
              {{ item.id }}
            </a>
          </v-container>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.roles="{ item }">
          <v-container class="role-container">
            {{ item.roles.join() }}</v-container
          >
        </template>
        <!-- eslint-disable-next-line -->
        <template v-if="showDeleted" v-slot:item.deleted="{ item }">
          <v-container>
            <v-icon v-if="item.deleted" v-text="'mdi-close-circle-outline'"
          /></v-container>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-container class="action-btn-container">
            <v-btn
              v-if="!item.deleted"
              small
              icon
              title="Login to cashdesk as this user"
              :disabled="disabled || runningAction"
              :data-test-id="'user_' + item.id + '_loginAsBtn'"
              @click="loginAs(item)"
            >
              <v-icon small>mdi-login</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              title="Edit user"
              :disabled="disabled || runningAction"
              :data-test-id="'user_' + item.id + '_editBtn'"
              @click="upsertUser(item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              title="Restore user"
              :disabled="disabled || runningAction"
              :data-test-id="'user_' + item.id + '_deleteBtn'"
              @click="deleteRestoreUser(item)"
            >
              <v-icon small>
                {{ item.deleted ? "mdi-arrow-u-up-right-bold" : "mdi-delete" }}
              </v-icon>
            </v-btn>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
    <!-- CREATE User DIALOG -->
    <v-dialog persistent v-model="userDialog" width="500px" v-if="userDialog">
      <User
        :reseller="reseller"
        :input-user="selectedUser"
        @close="closeUserDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import User from "./User";

export default {
  props: {
    reseller: {
      required: false,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  components: {
    User,
  },

  data() {
    return {
      selectedUser: {},
      showDeleted: false,
      userDialog: false,
      users: [],
      loading: false,
      runningAction: false,
    };
  },

  async mounted() {
    await this.loadUsers();
  },

  watch: {
    "reseller.id": function () {
      //another reseller has been selected
      this.loadUsers();
    },
  },

  methods: {
    async loadUsers() {
      if (this.reseller.id) {
        this.runningAction = true;
        //load the resellers users and categorize it into billing and shipping user
        this.users = await this.$store.$b2bApi.b2bResellerApi.getUsers(
          this.reseller.domain,
          this.reseller.id
        );
        if (!this.showDeleted)
          this.users = this.users.filter((user) => !user.deleted);
        this.runningAction = false;
      }
    },

    upsertUser(user) {
      this.userDialog = true;
      if (user) this.selectedUser = user;
      else this.selectedUser = {};
    },

    async deleteRestoreUser(user) {
      if (!user.deleted) {
        if (
          await this.$confirm(
            "Delete user?",
            "Are you sure you want to delete user " +
              user.firstname +
              " " +
              user.lastname +
              " (" +
              user.username +
              ")" +
              "?"
          )
        ) {
          user.deleted = true;
          await this.upsertUserCall(user);
          this.loadUsers();
        }
      } else {
        user.deleted = false;
        await this.upsertUserCall(user);
        this.loadUsers();
      }
    },

    async upsertUserCall(user) {
      this.runningAction = true;
      try {
        await this.$store.$b2bApi.b2bResellerApi.updateUser(
          this.reseller.domain,
          this.reseller.id,
          user
        );
      } finally {
        this.runningAction = false;
      }
    },

    closeUserDialog(reload) {
      this.userDialog = false;
      this.selectedUser = {};
      if (reload) this.loadUsers();
    },

    async loginAs(user) {
      try {
        this.runningAction = true;

        //Retrieve authToken and refreshToken for the selected reseller user
        const information =
          await this.$store.$b2bApi.b2bResellerApi.getLoginAsInformation(
            this.reseller.domain,
            this.reseller.id,
            user.id
          );

        if (!information) {
          this.$store.commit(
            "SET_ERROR",
            "Retrieval of auth information for provided user via Keycloak was not successful, login cannot be done!"
          );
          return;
        }

        //Load existing cashdesks
        const cashdeskRes =
          await this.$store.$b2bApi.b2bResellerApi.getCashdesks(
            this.reseller.domain,
            this.reseller.id
          );
        const cashdesks = Array.isArray(cashdeskRes) ? cashdeskRes : [];
        let cashdesk;

        if (cashdesks.length === 0) {
          this.$store.commit("SET_ERROR", "No cashdesks configured");
          return;
        }

        if (cashdesks.length > 1) {
          //If there is more than one cashdesk, the user has to select
          const options = cashdesks.map(({ key, name }) => {
            return {
              text: name,
              value: key,
            };
          });

          cashdesk = await this.$prompt("Please select cashdesk", {
            rules: [(val) => !!val || "Cashdesk is required"],
            options,
          });

          if (!cashdesk) return;
        } else {
          cashdesk = cashdesks[0]?.key;
        }

        const res = await this.$store.$b2bApi.b2bResellerApi.loginAs(
          this.reseller.domain,
          {
            ...information,
            cashdesk,
          }
        );
        if (!res?.ok) {
          this.$store.commit("SET_ERROR", "Login as selected user failed");
          return;
        }
        // eslint-disable-next-line
        const cashdeskUiDomain = BASE_CONFIGURATION.cashdeskUiDomain;
        const url =
          cashdeskUiDomain + "/" + this.reseller.domain + "/b2b/index.html";
        window.open(url, "_blank", "noopener");
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    userHeaders() {
      let headers = [
        { text: "Customer ID", value: "id" },
        { text: "Username", value: "username" },
        { text: "First Name", value: "firstname" },
        { text: "Last Name", value: "lastname" },
        { text: "Email", value: "email" },
        { text: "Locale", value: "locale" },
        { text: "Roles", value: "roles" },
        { text: "Deleted", value: "deleted" },
        { text: "Actions", value: "actions", sortable: false },
      ];

      if (!this.showDeleted) {
        return headers.filter((header) => header.value !== "deleted");
      }

      return headers;
    },
  },
};
</script>

<style scoped>
.action-btn-container {
  padding: 0;
  display: flex;
}

.reseller-users {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
}
</style>

<style>
.reseller-users-table tr.deleted {
  opacity: 0.5;
}
</style>