<template>
  <MasterDetail
    data-test-id="ResellerOverview"
    :detail-open="!!selectedReseller"
    @table:resize="tableWidth = $event"
  >
    <template #toolbar>
      <Toolbar
        v-model="filterQuery"
        class="reseller-table-toolbar"
        :key="possibleFilters.length"
        :search-props="{
          disableFullTextSearch: true,
          possibleFilters,
        }"
        @update-filters="(updated) => (filters = updated)"
      >
        <template #extended>
          <div class="extended-toolbar">
            <v-select
              class="reseller-limit-select"
              dense
              hide-details
              v-model="limit"
              :items="pageLimits"
              :disabled="runningAction"
              data-test-id="pageLimitSelector"
            />
            rows
            <v-divider class="mx-4" vertical />

            <!-- CUSTOM PAGINATION COMPONENT -->
            <PaginationComponent
              v-if="totalPages > 0"
              v-model="currentPage"
              :totalPages="totalPages"
              :disabled="runningAction"
            />
            <v-divider class="mx-4" v-if="totalPages > 0" vertical />
            <span>{{ total }} total</span>

            <v-spacer />
            <v-switch
              data-test-id="deletedSwitch"
              v-model="showDeleted"
              label="Show deleted"
              color="primary"
              hide-details
              class="mt-0 ml-3"
              :disabled="runningAction"
              @change="loadResellers"
            />
            <v-divider class="mx-4" vertical />

            <v-btn
              color="primary"
              data-test-id="newResellerBtn"
              title="Add new reseller"
              :fab="showMinified"
              :small="showMinified"
              @click="openResellerDialog"
            >
              <v-icon v-if="showMinified">mdi-plus</v-icon>
              <div v-else>New reseller</div>
            </v-btn>
          </div>
        </template>
      </Toolbar>

      <!-- CREATE reseller DIALOG -->
      <v-dialog
        persistent
        v-model="createReseller"
        width="500px"
        v-if="createReseller"
      >
        <Reseller @close="closeDialog" />
      </v-dialog>
    </template>

    <template #table>
      <!-- RESELLER TABLE -->
      <v-data-table
        dense
        disable-pagination
        hide-default-footer
        class="reseller-table"
        fixed-header
        :items="resellers"
        :headers="headers"
        :loading="runningAction"
        :single-select="true"
        :height="tableHeight"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="selectReseller(item)"
            :class="{
              selected: selectedReseller && item.id == selectedReseller.id,
              deleted: showDeleted && item.deleted,
            }"
            :data-test-id="'reseller_' + item.id"
          >
            <td
              class="reseller-domain"
              :data-test-id="'reseller_domain_' + item.id"
            >
              {{ item.domain }}
            </td>
            <td
              class="reseller-name"
              :data-test-id="'reseller_name_' + item.id"
            >
              {{ item.name }}
            </td>
            <td
              class="reseller-email"
              :data-test-id="'reseller_email_' + item.id"
            >
              {{ item.email }}
            </td>
            <td
              class="reseller-locale"
              :data-test-id="'reseller_locale_' + item.id"
            >
              {{ item.locale }}
            </td>
            <td
              v-if="showDeleted"
              class="reseller-deleted"
              :data-test-id="'reseller_deleted_' + item.id"
            >
              <v-icon v-if="item.deleted" v-text="'mdi-close-circle-outline'" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template #detail>
      <ResellerDetails
        v-if="selectedReseller"
        data-test-id="resellerDetail"
        :input-reseller="selectedReseller"
        :key="selectedReseller.id"
        :style="{
          height: detailHeight + 'px',
        }"
        @close="closeResellerDetails"
        @refresh="loadResellers"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "../common/templates/MasterDetail";
import ResellerDetails from "./ResellerDetails";
import Reseller from "./Reseller";
import PaginationComponent from "../PaginationComponent";
import Toolbar from "../common/templates/Toolbar";
import mainOverviewMixin from "../../mixins/main-overview-mixin";

export default {
  mixins: [mainOverviewMixin],

  provide() {
    return {
      getAllResellers: this.getAllResellers,
    };
  },

  components: {
    MasterDetail,
    ResellerDetails,
    PaginationComponent,
    Toolbar,
    Reseller,
  },

  data() {
    return {
      resellers: [],
      locales: [],
      createReseller: false,
      selectedReseller: null,
      runningAction: false,
      currentPage: 1,
      total: 0,
      filterQuery: null,
      showDeleted: false,
      limit: 20,
      filters: {},
      tableWidth: 0,
    };
  },

  async created() {
    await this.init();
  },

  watch: {
    "$route.params": async function () {
      if (this.$route.params.reseller) await this.init();
    },

    filters: {
      handler: async function () {
        let query = Object.assign({}, this.$route.query);
        //remove filter query if the query is faulty
        if (Object.keys(this.filters) > 0) {
          if (typeof this.filterQuery == "object")
            this.filterQuery = JSON.stringify(this.filterQuery);
          query.filter = this.$urlEncode(this.filterQuery);
        } else {
          delete query.filter;
        }
        this.$router.replace({ query });
        await this.loadResellers();
      },
      deep: true,
    },

    async currentPage() {
      //reload resellers if the current page changes
      if (this.currentPage < 1) this.currentPage = 1;

      if (this.currentPage > 0 && this.currentPage <= this.totalPages) {
        await this.loadResellers();
        this.$router.replace({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
          }),
        });
      }
    },

    totalPages() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    },

    async limit() {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { limit: this.limit }),
      });

      if (this.totalPages == 0) return;

      //changed the limit of entries on a page, therefore recalculate current pages
      if (this.totalPages > 0 && this.currentPage > this.totalPages) {
        //change of current page triggers a reload
        this.currentPage = this.totalPages;
      } else {
        //current page did not change, so reload resellers manually
        await this.loadResellers();
      }
    },
  },

  methods: {
    async init() {
      //initialize the component with the route parameters
      let namedRoute = this.$route.name;
      let routeQuery = this.$route.query;
      let page = routeQuery.page;
      let limit = routeQuery.limit;
      let filter = routeQuery.filter;
      const resellerDomain = routeQuery.resellerDomain;

      let pageChange = false;
      let filterChange = false;

      if (
        namedRoute === "reseller" ||
        (namedRoute === "resellerDetail" && !this.selectedReseller)
      ) {
        //load reseller if the route is correct or the reseller editor is accessed directly via URL
        await this.loadResellers();
      }

      if (page) {
        page = parseInt(page, 10);
        if (Number.isNaN(page)) page = 1;
        pageChange = page != this.currentPage;
        this.currentPage = page;
      }

      if (limit) {
        limit = parseInt(limit, 10);
        if (Number.isNaN(limit) || !this.pageLimits.includes(limit))
          limit = this.pageLimits[0];
        this.limit = limit;
      }

      if (filter) {
        try {
          this.filterQuery = this.$urlDecode(filter);
          filterChange = true;
        } catch (e) {
          this.filterQuery = null;
          console.warn(e);
        }
      }

      let query = {};
      if (this.currentPage) query.page = this.currentPage;
      if (this.limit) query.limit = this.limit;
      if (this.filterQuery) query.filter = this.$urlEncode(this.filterQuery);
      if (resellerDomain) query.resellerDomain = resellerDomain;
      //update the URL query
      this.$router.replace({ query });

      if (
        (namedRoute == "resellers" ||
          (namedRoute == "resellerDetail" &&
            (pageChange || !this.selectedReseller))) &&
        !filterChange
      ) {
        //load resellers either if the table page has changed or the reseller is requested directly via URL
        //if a filter is set as query parameter, do not load it here, because the resellers are already reloaded when changing the filter
        await this.loadResellers();
      }

      if (namedRoute === "resellerDetail") {
        //show detail view of requested reseller
        const resellerId = this.$route.params.reseller;

        if (!resellerId) {
          this.closeResellerDetails(true);
        } else if (
          !this.selectedReseller ||
          resellerId != this.selectedReseller.id
        ) {
          const reseller =
            await this.$store.$b2bApi.b2bResellerApi.loadReseller(
              this.selectedReseller
                ? this.selectedReseller.domain
                : resellerDomain,
              resellerId
            );

          if (!reseller) {
            this.closeResellerDetails(true);
            return;
          }
          this.selectReseller(reseller);
        }
      }
    },

    closeDialog(reload) {
      this.createReseller = false;
      if (reload) this.loadResellers();
    },

    async loadResellers() {
      this.runningAction = true;
      try {
        const resellerData =
          await this.$store.$b2bApi.b2bResellerApi.getResellers(
            this.selectedDomain,
            this.filters,
            this.currentPage,
            this.limit
          );
        this.resellers = resellerData.result;
        if (!this.showDeleted)
          this.resellers = this.resellers.filter(
            (reseller) => !reseller.deleted
          );
        this.total = resellerData.total;
      } finally {
        this.runningAction = false;
      }
    },

    async selectReseller(reseller) {
      this.selectedReseller = reseller;
      if (this.selectedReseller) {
        this.$router.replace({
          name: "resellerDetail",
          params: { reseller: reseller.id },
          query: Object.assign({}, this.$route.query, {
            resellerDomain: reseller.domain,
          }),
        });
      }
    },

    async closeResellerDetails(reload) {
      await this.$router.push({
        name: "resellers",
        query: this.$route.query,
      });
      this.selectedReseller = null;
      if (reload) await this.loadResellers();
    },

    getAllResellers() {
      return this.resellers;
    },

    async openResellerDialog() {
      await this.closeResellerDetails();
      this.createReseller = true;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    totalPages() {
      return Math.ceil(this.total / this.limit);
    },

    headers() {
      let headers = [
        { text: "Domain", value: "domain" },
        { text: "Name", value: "name" },
        { text: "E-Mail", value: "email" },
        { text: "Locale", value: "locale" },
        { text: "Deleted", value: "deleted" },
      ];

      if (!this.showDeleted) {
        return headers.filter((header) => header.value !== "deleted");
      }

      return headers;
    },

    pageLimits() {
      return [20, 100, 200];
    },

    possibleFilters() {
      return [
        { text: "Name", property: "name", type: "text" },
        { text: "E-Mail", property: "email", type: "text" },
      ];
    },

    showMinified() {
      return (
        (this.selectedReseller && this.tableWidth <= 900) ||
        this.getPageWidth() <= 900
      );
    },
  },
};
</script>

<style scoped>
* {
  text-align: left;
}

.reseller-table-toolbar .extended-toolbar {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 12px 8px 12px;
}

.reseller-container {
  flex-flow: column nowrap;
  align-items: flex-start;
  height: 100%;
  padding: 0;
}

.reseller-table-container,
.reseller-table-container {
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.reseller-table-container {
  flex-flow: row nowrap;
  padding: 0;
  overflow-y: hidden;
}

.reseller-toolbar {
  margin-bottom: 10px;
}

.reseller-table-container {
  overflow-y: scroll;
  height: 100%;
}

.reseller-table-container.editor-opened {
  flex-shrink: 5;
}

.reseller-table {
  width: 100%;
}

.reseller-form {
  padding: 0;
  display: flex;
  overflow-y: hidden;
  align-items: flex-start;
  border: 1px solid var(--v-psblue-base);
}

.reseller-item {
  flex-flow: row wrap;
  text-align: left;
  padding-top: 5px;
}

.new-reseller-form {
  padding: 5px;
}

.reseller-limit-select {
  max-width: 70px;
  margin-right: 5px;
}
</style>

<style>
.reseller-table tr.selected {
  background-color: var(--v-psblue-base);
  color: white;
}

.reseller-table tr.deleted {
  opacity: 0.5;
}
</style>

<style>
/* Do not use this class in scoped, because then the style does won't work 
on the first row in the data table. This is caused by of a missing "data-v-..." attribute */
.reseller-table-container
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.selected {
  background-color: var(--v-psblue-base);
  color: white;
}
</style>

