var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DetailView',{staticClass:"reseller-editor",attrs:{"title":_vm.title,"loading":_vm.runningAction,"tabs":_vm.tabs},on:{"close":function($event){return _vm.closeEditor()}},scopedSlots:_vm._u([{key:"tab.overview",fn:function(ref){
var loading = ref.loading;
return [_c('v-form',{ref:"resellerForm",staticClass:"reseller-form",attrs:{"data-test-id":"resellerForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('Reseller',{ref:"reseller",on:{"close":_vm.closeEditor,"refresh":_vm.refreshAll},model:{value:(_vm.reseller),callback:function ($$v) {_vm.reseller=$$v},expression:"reseller"}}),_c('v-card',{staticClass:"reseller-section reseller-addresses",attrs:{"flat":"","id":"reseller-addresses","data-test-id":"resellerAddresses"}},[(_vm.showAddressHint)?_c('v-alert',{staticClass:"pl-3",attrs:{"border":"left","type":"warning","data-test-id":"AddressHint"}},[_vm._v(" Reseller customer should have billing address, please add it ")]):_vm._e(),_c('AddressOverview',{key:_vm.resellerCustomer.id,staticClass:"pa-3",attrs:{"customer":_vm.resellerCustomer,"domain":_vm.reseller.domain,"disabled":_vm.runningAction,"data-test-id":"resellerCustomerAddresses"},on:{"addresses":_vm.checkAddresses}})],1),_c('v-card',{staticClass:"reseller-section reseller-users d-flex",attrs:{"flat":"","id":"reseller-users","data-test-id":"resellerExRates"}},[_c('ExRateList',{attrs:{"reseller":_vm.reseller,"disabled":_vm.runningAction,"data-test-id":"resellerExRateList"}})],1)],1)]}},{key:"tab.users",fn:function(ref){
var loading = ref.loading;
return [_c('v-container',{staticClass:"reseller-cashdesks-container",attrs:{"fluid":""}},[_c('UserList',{attrs:{"reseller":_vm.reseller,"disabled":_vm.runningAction,"data-test-id":"resellerUserList"}})],1)]}},{key:"tab.cashdesks",fn:function(ref){
var loading = ref.loading;
return [_c('v-container',{staticClass:"reseller-cashdesks-container",attrs:{"fluid":""}},[_c('CashdeskList',{attrs:{"reseller":_vm.reseller,"disabled":_vm.runningAction}})],1)]}},{key:"tab.contracts",fn:function(ref){
var loading = ref.loading;
return [_c('v-container',{staticClass:"reseller-contracts-container",attrs:{"fluid":""}},[_c('ContractList',{attrs:{"entity":_vm.reseller,"domain":_vm.reseller.domain,"disabled":_vm.runningAction || _vm.disableContractUpsert,"data-test-id":"resellerContractList"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }