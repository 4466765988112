<template>
  <DialogForm
    data-test-id="printer"
    save-btn-test-id="createPrinterBtn"
    close-btn-test-id="closePrinterDialogBtn"
    :save-btn-label="modeCreate ? 'Create' : 'Save'"
    :loading="runningAction"
    :title="title"
    @close="closeDialog()"
    @save="upsertPrinter"
  >
    <template #form>
      <v-container>
        <v-form @submit.prevent ref="printerForm">
          <v-row>
            <v-col>
              <v-text-field
                v-model="printer.name"
                dense
                outlined
                label="Name*"
                class="printer-name"
                hide-details="auto"
                data-test-id="printerName"
                :rules="inputRules"
                :disabled="disabled || runningAction"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="printer.type"
                dense
                outlined
                label="Type*"
                class="printer-type"
                data-test-id="printerType"
                hide-details="auto"
                :rules="inputRules"
                :items="printerTypes"
                :disabled="disabled || runningAction"
              />
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="printer.config.local"
                dense
                label="Local"
                class="printer-local mt-1"
                data-test-id="printerLocal"
                hide-details="auto"
                :disabled="disabled || runningAction"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="printer.config.host"
                dense
                outlined
                label="Host"
                class="printer-host"
                data-test-id="printerHost"
                hide-details="auto"
                :rules="inputRules"
                :disabled="disabled || runningAction"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="printer.config.port"
                dense
                outlined
                label="Port"
                class="printer-port"
                data-test-id="printerPort"
                type="number"
                hide-details="auto"
                :rules="inputRules"
                :disabled="disabled || runningAction"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.number="printer.config.timeout"
                dense
                outlined
                label="Timeout"
                class="printer-timeout"
                data-test-id="printerTimeout"
                hide-details="auto"
                type="number"
                :disabled="disabled || runningAction"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.number="printer.config.deviceNumber"
                dense
                outlined
                label="Device Nr."
                class="printer-device-number"
                data-test-id="printerDeviceNumber"
                hide-details="auto"
                type="number"
                :disabled="disabled || runningAction"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="printer.config.trayConfig"
                dense
                outlined
                label="Tray configuration"
                class="printer-tray-config"
                data-test-id="printerTrayConfig"
                hide-details="auto"
                :items="trayConfigurations"
                :disabled="disabled || runningAction"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </template>
  </DialogForm>
</template>


<script>
import DialogForm from "../../../common/templates/DialogForm";
import ResellerEnum from "../../../../mixins/enum/ResellerEnum";

export default {
  name: "Printer",
  mixins: [ResellerEnum],
  components: {
    DialogForm,
  },
  props: {
    inputPrinter: {
      required: false,
      default: () => {
        return {};
      },
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    reseller: {
      required: true,
      type: Object,
    },
    cashdesk: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      printer: this.initPrinter(),
      runningAction: false,
      inputRules: [(v) => !!v || v === 0 || "Value is required"],
    };
  },

  watch: {
    "inputPrinter.id": function () {
      //another printer has been selected
      this.printer = this.inputPrinter;
    },
  },

  methods: {
    async upsertPrinter() {
      if (this.$refs.printerForm.validate()) {
        this.runningAction = true;
        try {
          if (!this.printer.id) {
            this.printer.id = this.$uuid.v4();
          }
          const res =
            await this.$store.$b2bApi.b2bResellerApi.upsertCashdeskPrinter(
              this.reseller.domain,
              this.reseller.id,
              this.cashdesk.id,
              this.printer
            );

          if (!res?.ok) return;

          this.closeDialog(true);
        } finally {
          this.runningAction = false;
        }
      }
    },

    closeDialog(reload) {
      this.printer = {};
      this.$emit("close", reload);
    },

    initPrinter() {
      let printer = this.$cloneObject(this.inputPrinter);
      if (!printer.config) {
        //set default values defined by API
        this.$set(printer, "config", {
          deviceNumber: 1,
          local: true,
          timeout: 10,
          trayConfig: "skipass_bergbahn",
        });
      }
      return printer;
    },
  },

  computed: {
    modeCreate() {
      return !this.inputPrinter?.id;
    },

    title() {
      return !this.modeCreate ? "Update Printer" : "Create Printer";
    },
  },
};
</script>

<style scoped>
</style>