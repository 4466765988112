<template>
  <DetailView
    class="reseller-editor"
    :title="title"
    :loading="runningAction"
    :tabs="tabs"
    @close="closeEditor()"
  >
    <!-- eslint-disable-next-line -->
    <template #tab.overview="{ loading }">
      <v-form
        @submit.prevent
        ref="resellerForm"
        class="reseller-form"
        data-test-id="resellerForm"
      >
        <!-- COMMON ATTRIBUTES -->

        <Reseller
          v-model="reseller"
          ref="reseller"
          @close="closeEditor"
          @refresh="refreshAll"
        />

        <v-card
          flat
          class="reseller-section reseller-addresses"
          id="reseller-addresses"
          data-test-id="resellerAddresses"
        >
          <v-alert
            v-if="showAddressHint"
            class="pl-3"
            border="left"
            type="warning"
            data-test-id="AddressHint"
          >
            Reseller customer should have billing address, please add it
          </v-alert>
          <!-- ADDRESSES -->
          <AddressOverview
            :customer="resellerCustomer"
            :domain="reseller.domain"
            :key="resellerCustomer.id"
            :disabled="runningAction"
            data-test-id="resellerCustomerAddresses"
            class="pa-3"
            @addresses="checkAddresses"
          />
        </v-card>
        <v-card
          flat
          class="reseller-section reseller-users d-flex"
          id="reseller-users"
          data-test-id="resellerExRates"
        >
          <!-- EXRATE -->
          <ExRateList
            :reseller="reseller"
            :disabled="runningAction"
            data-test-id="resellerExRateList"
          />
        </v-card>
      </v-form>
    </template>
    <!-- eslint-disable-next-line -->
    <template #tab.users="{ loading }">
      <v-container fluid class="reseller-cashdesks-container">
        <!-- USER -->
        <UserList
          :reseller="reseller"
          :disabled="runningAction"
          data-test-id="resellerUserList"
        />
      </v-container>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.cashdesks="{ loading }">
      <v-container fluid class="reseller-cashdesks-container">
        <!-- CASHDESK -->
        <CashdeskList :reseller="reseller" :disabled="runningAction" />
      </v-container>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.contracts="{ loading }">
      <v-container fluid class="reseller-contracts-container">
        <!-- CONTRACT -->
        <ContractList
          :entity="reseller"
          :domain="reseller.domain"
          :disabled="runningAction || disableContractUpsert"
          data-test-id="resellerContractList"
        />
      </v-container>
    </template>
  </DetailView>
</template>

<script>
import DetailView from "../common/templates/DetailView";
import Reseller from "./Reseller";
import AddressOverview from "../customer/address/AddressOverview";
import UserList from "./user/UsersList";
import ExRateList from "./exrate/ExRatesList";
import CashdeskList from "./cashdesk/CashdesksList";
import ContractList from "../commission-contract/ContractsList";

export default {
  props: {
    inputReseller: {
      required: false,
      default: null,
      type: Object,
    },
  },
  components: {
    DetailView,
    Reseller,
    AddressOverview,
    UserList,
    ExRateList,
    CashdeskList,
    ContractList,
  },

  data() {
    return {
      reseller: this.$cloneObject(this.inputReseller),
      resellerCustomer: { id: this.inputReseller.id },
      showAddressHint: true,
      menu: false,
      selectedTab: null,
      runningAction: false,
    };
  },

  watch: {
    "inputReseller.id": function () {
      //another user has been selected
      this.reseller = this.inputReseller;
      this.resellerCustomer = { id: this.inputReseller.id };
    },
  },

  methods: {
    refreshAll() {
      this.loadReseller();
      this.$emit("refresh");
    },

    closeEditor(reload) {
      this.$emit("close", reload);
    },

    checkAddresses(addresses) {
      this.showAddressHint = true;
      if (
        addresses &&
        addresses.filter((a) => a.type === "BILLING_ADDRESS")?.length > 0
      ) {
        this.showAddressHint = false;
      }
    },

    async loadReseller() {
      this.runningAction = true;
      try {
        this.reseller = await this.$store.$b2bApi.b2bResellerApi.loadReseller(
          this.reseller.domain,
          this.reseller.id
        );

        if (!this.reseller) {
          this.closeEditor(true);
          return;
        }
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    tabs() {
      return [
        { text: "Overview", value: "overview" },
        { text: "Users", value: "users" },
        { text: "Cashdesks", value: "cashdesks" },
        { text: "Contracts", value: "contracts" },
      ];
    },

    title() {
      return this.reseller.name;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    disableContractUpsert() {
      return this.selectedDomain === this.reseller.domain;
    },
  },
};
</script>

<style scoped>
.reseller-section {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}

.reseller-editor {
  padding: 0px;
  border: 1px solid var(--v-psblue-base);
  overflow-y: hidden;
  align-items: flex-start;
  align-content: flex-start;
}

.reseller-form-container {
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 5px;
  padding: 0px;
  height: calc(100% - 160px);
  overflow-y: scroll;
}

.reseller-editor-toolbar-btn {
  margin-right: 5px;
}
</style>