<template>
  <v-container
    fluid
    fill-height
    class="reseller-cashdesk-container"
    data-test-id="CashdesksList"
  >
    <v-card flat class="reseller-cashdesks cashdesk-section" id="cashdesks">
      <v-card-title>
        <v-icon left>mdi-cash-register</v-icon>
        <span>Cashdesks</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          data-test-id="addCashdeskBtn"
          :disabled="disabled || loadingDelete"
          @click="addCashdesk"
        >
          New Cashdesk
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        sort-by="preferred"
        :sort-desc="true"
        :items="cashdesks"
        :headers="cashdeskHeaders"
        show-expand
        :expanded.sync="expanded"
        :loading="runningAction"
        hide-default-footer
        :items-per-page="-1"
        no-data-text="No cashdesks found"
        class="reseller-cashdesks-table"
        data-test-id="resellerCashdeskList"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item="{ item, isExpanded, expand }">
          <tr
            :class="{
              'v-data-table__expanded v-data-table__expanded__row': isExpanded,
            }"
          >
            <td
              class="reseller-cashdesk-expand"
              :data-test-id="'reseller-cashdesk-expand' + item.id"
            >
              <v-btn
                icon
                v-if="isExpanded"
                :loading="loading[item.id]"
                @click="expand(false)"
                :data-test-id="'cashdesk_' + item.id + '_hide_btn'"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn
                icon
                v-else
                :loading="loading[item.id]"
                :disabled="loading[item.id]"
                @click="expand(true)"
                :data-test-id="'cashdesk_' + item.id + '_expand_btn'"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </td>
            <td
              class="reseller-cashdesk-name"
              :data-test-id="'reseller-cashdesk-name' + item.id"
            >
              {{ item.name }}
            </td>
            <td
              class="reseller-cashdesk-key"
              :data-test-id="'reseller-cashdesk-key' + item.id"
            >
              {{ item.key }}
            </td>
            <td
              class="reseller-cashdesk-action"
              :data-test-id="'reseller-cashdesk-action' + item.id"
            >
              <v-btn
                small
                icon
                @click="deleteCashdesk(item)"
                :disabled="disabled || loadingDelete"
                :loading="loadingDelete"
                :data-test-id="'cashdesk_' + item.id + '_deleteBtn'"
              >
                <v-icon v-if="!loadingDelete" small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ item }">
          <tr class="v-data-table__expanded v-data-table__expanded__content">
            <td :colspan="cashdeskHeaders.length + 1">
              <v-container :data-test-id="'cashdesk_' + item.id + '_detail'">
                <Cashdesk
                  :reseller="reseller"
                  :input-cashdesk="item"
                  :disabled="disabled || loadingDelete"
                  :dimension-skipass="dimensionSkipass"
                  @refresh="loadCashdesks()"
                />
                <!-- PRINTERS -->
                <PrintersList
                  :reseller="reseller"
                  :cashdesk="item"
                  :disabled="disabled || loadingDelete"
                  data-test-id="resellerCashdeskPrintersList"
                />
              </v-container>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <!-- CREATE Cashdesk DIALOG -->
    <v-dialog
      persistent
      v-model="cashdeskDialog"
      width="500px"
      v-if="cashdeskDialog"
    >
      <Cashdesk
        :reseller="reseller"
        :input-cashdesk="{}"
        @close="closeCashdeskDialog"
        data-test-id="cashdeskDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Cashdesk from "./Cashdesk";
import PrintersList from "./printer/PrintersList";

export default {
  props: {
    reseller: {
      required: false,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  components: {
    Cashdesk,
    PrintersList,
  },

  data() {
    return {
      cashdeskDialog: false,
      cashdesks: [],
      expanded: [],
      loading: {},
      printers: [],
      dimensionSkipass: [],
      runningAction: false,
      loadingDelete: false,
    };
  },

  async mounted() {
    await this.loadCashdesks();
    this.dimensionSkipass =
      await this.$store.$coreApi.coreConfigurationApi.getProductTypeDimensions(
        this.reseller.domain,
        "SKIPASS"
      );
  },

  watch: {
    "reseller.id": function () {
      //another reseller has been selected
      this.loadCashdesks();
    },
  },

  methods: {
    async loadCashdesks() {
      if (this.reseller.id) {
        this.runningAction = true;
        //load the resellers cashdesks and categorize it into billing and shipping cashdesk
        this.cashdesks = await this.$store.$b2bApi.b2bResellerApi.getCashdesks(
          this.reseller.domain,
          this.reseller.id
        );
        this.runningAction = false;
      }
    },

    addCashdesk() {
      this.cashdeskDialog = true;
    },

    async deleteCashdesk(cashdesk) {
      if (!cashdesk.deleted) {
        if (
          await this.$confirm(
            "Delete cashdesk?",
            "Are you sure you want to delete cashdesk " +
              cashdesk.name +
              " (" +
              cashdesk.key +
              ")" +
              "?"
          )
        ) {
          this.loadingDelete = true;
          try {
            await this.$store.$b2bApi.b2bResellerApi.deleteCashdesk(
              this.reseller.domain,
              this.reseller.id,
              cashdesk
            );
          } finally {
            this.loadingDelete = false;
            this.loadCashdesks();
          }
        }
      }
    },

    async closeCashdeskDialog(reload) {
      this.cashdeskDialog = false;
      if (reload) {
        await this.loadCashdesks();
        this.expanded = this.cashdesks.filter((cd) => cd.id == reload);
      }
    },
  },

  computed: {
    cashdeskHeaders() {
      return [
        { text: "Name", value: "name" },
        { text: "Key", value: "key" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.action-btn-container {
  padding: 0;
  display: flex;
}

.cashdesk-section {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}
</style>