<template>
  <v-card class="new-cashdesk-form" data-test-id="cashdesk" flat>
    <v-toolbar flat>
      <v-toolbar-title v-if="modeCreate">Create Cashdesk</v-toolbar-title>
      <v-divider vertical inset class="mx-4" v-if="modeCreate"></v-divider>
      <v-spacer></v-spacer>

      <v-btn
        v-if="modeCreate"
        @click="upsertCashdesk"
        :loading="runningAction"
        :disabled="runningAction"
        color="green"
        data-test-id="createCashdeskBtn"
      >
        Save
      </v-btn>
      <v-btn
        v-if="modeCreate"
        @click="closeDialog()"
        text
        class="close-dialog-btn"
        data-test-id="closeCashdeskDialogBtn"
      >
        Cancel
      </v-btn>

      <v-btn
        icon
        v-if="!modeCreate && !edit"
        @click="editCashdesk"
        :disabled="disabled"
        :data-test-id="'cashdesk_editBtn'"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="!modeCreate && edit"
        @click="updateCashdesk"
        :disabled="runningAction"
        :data-test-id="'cashdesk_updateBtn'"
      >
        <v-icon small>mdi-content-save-outline</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="!modeCreate && edit"
        @click="stopEdit"
        :disabled="runningAction"
        :data-test-id="'cashdesk_closeBtn'"
      >
        <v-icon small>mdi-pencil-off</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form @submit.prevent ref="cashdeskForm">
      <v-container>
        <v-text-field
          dense
          outlined
          v-model="cashdesk.name"
          label="Cashdesk name*"
          class="create-cashdesk-name"
          :rules="inputRules"
          data-test-id="cashdeskName"
          :disabled="isDisabled"
        />

        <v-text-field
          dense
          outlined
          v-model="cashdesk.key"
          label="Cashdesk key*"
          class="create-cashdesk-key"
          :rules="inputRules"
          data-test-id="cashdeskKey"
          :disabled="isDisabled"
        />

        <v-card
          v-if="!modeCreate"
          class="pa-2"
          outlined
          data-test-id="cashdeskConfiguration"
        >
          <v-card-title>
            <v-icon left>mdi-cog</v-icon>Configuration
          </v-card-title>

          <v-subheader class="cashdesk-config">Order</v-subheader>
          <v-text-field
            dense
            outlined
            type="number"
            v-model="cashdesk.config.order.cancellationPeriod"
            label="Cancellation period (minutes)"
            class="create-cashdesk-cancellationPeriod"
            data-test-id="cashdeskCancellationPeriod"
            placeholder="2880"
            :disabled="!edit || runningAction"
          />
          <v-subheader
            class="cashdesk-config"
            data-test-id="cashdeskConfigurationSkipass"
            >Skipass</v-subheader
          >
          <v-select
            dense
            outlined
            v-model="cashdesk.config.skipass.cohortFilter"
            :items="cohort"
            multiple
            label="Cohort filter"
            data-test-id="cashdeskCohortFilter"
            :disabled="!edit || runningAction"
          />
          <v-row>
            <v-col :cols="3">
              <v-checkbox
                dense
                class="mt-0"
                v-model="cashdesk.config.skipass.showReduced"
                label="Show reduced"
                outlined
                hide-details="auto"
                :disabled="!edit || runningAction"
                data-test-id="cashdeskShowReduced"
              />
            </v-col>
            <v-col :cols="9">
              <v-select
                dense
                outlined
                v-model="cashdesk.config.skipass.reductionFilter"
                :items="reduction"
                multiple
                label="Reduction filter"
                data-test-id="cashdeskReductionFilter"
                :disabled="isReductionFilterDisabled"
              />
            </v-col>
          </v-row>
          <v-select
            dense
            outlined
            v-model="cashdesk.config.skipass.regionFilter"
            :items="region"
            multiple
            label="Region filter"
            data-test-id="cashdeskRegionFilter"
            :disabled="!edit || runningAction"
          />
        </v-card>
      </v-container>
    </v-form>
  </v-card>
</template>


<script>
export default {
  name: "Cashdesk",
  props: {
    inputCashdesk: {
      required: false,
      default: null,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    reseller: {
      required: true,
      type: Object,
    },
    dimensionSkipass: {
      required: false,
      type: Array,
    },
  },

  data() {
    return {
      cashdesk: this.initCashDesk(),
      runningAction: false,
      inputRules: [(v) => !!v || "Value is required"],
      cohort: [],
      reduction: [],
      region: [],
      edit: false,
    };
  },

  watch: {
    "inputCashdesk.id": function () {
      //another cashdesk has been selected
      this.cashdesk = this.inputCashdesk;
    },
    inputCashdesk: {
      deep: true,
      handler: function () {
        this.cashdesk = this.initCashDesk();
      },
    },
  },

  async mounted() {
    this.initDimensions();
    this.reduction = this.reduction.filter((rdtn) => rdtn.value !== "none");
  },

  computed: {
    isDisabled() {
      return (!this.edit || this.runningAction) && !this.modeCreate;
    },
    modeCreate() {
      return !this.inputCashdesk || !this.inputCashdesk.id;
    },
    isReductionFilterDisabled() {
      return (
        !this.edit ||
        !this.cashdesk.config.skipass.showReduced ||
        this.runningAction
      );
    },
  },

  methods: {
    async upsertCashdesk() {
      if (this.$refs.cashdeskForm.validate()) {
        this.runningAction = true;
        try {
          if (!this.cashdesk.id) {
            this.cashdesk.id = this.$uuid.v4();
          }
          const res = await this.$store.$b2bApi.b2bResellerApi.upsertCashdesk(
            this.reseller.domain,
            this.reseller.id,
            this.cashdesk
          );

          if (!res?.ok) return;

          this.closeDialog(this.cashdesk.id);
        } finally {
          this.runningAction = false;
        }
      }
    },

    closeDialog(reload) {
      this.createCashdesk = false;
      this.cashdesk = {};
      this.$emit("close", reload);
    },

    editCashdesk() {
      this.edit = true;
    },

    async updateCashdesk() {
      await this.upsertCashdesk();
      this.edit = false;
      this.$emit("refresh");
    },

    stopEdit() {
      this.edit = false;
      this.cashdesk = this.initCashDesk();
    },

    initCashDesk() {
      let cashdesk = !this.modeCreate
        ? this.$cloneObject(this.inputCashdesk)
        : {};

      if (!cashdesk.config) cashdesk.config = {};

      if (!cashdesk.config.order) cashdesk.config.order = {};

      if (!cashdesk.config.skipass) cashdesk.config.skipass = {};
      return cashdesk;
    },

    initDimensions() {
      let dimensionSkipass = this.dimensionSkipass;
      if (dimensionSkipass) {
        for (const dim of dimensionSkipass) {
          this.setDimensionsValues(dim, "cohort", this.cohort);
          this.setDimensionsValues(dim, "region", this.region);
          this.setDimensionsValues(dim, "reduction", this.reduction);
        }
      }
    },

    setDimensionsValues(dimension, key, list) {
      if (dimension.key === key)
        for (const opt of dimension.optionEnumValues) {
          list.push({ text: opt.labels["en-GB"], value: opt.code });
        }
    },
  },
};
</script>

<style scoped>
.cashdesk_editBtn {
  position: relative;
}

.cashdesk-config {
  font-size: 1.2em;
}
</style>