<template>
  <v-container
    fluid
    fill-height
    class="reseller-printer-container"
    data-test-id="PrintersList"
  >
    <v-card flat class="reseller-printers" id="printers">
      <v-card-title>
        <v-icon left>mdi-printer</v-icon>
        Printers
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          data-test-id="upsertPrinterBtn"
          :disabled="disabled || loadingDelete"
          @click="upsertPrinter()"
        >
          Add printer
        </v-btn>
      </v-card-title>
      <v-data-table
        dense
        sort-by="preferred"
        :sort-desc="true"
        :items="printers"
        :loading="runningAction"
        :headers="printerHeaders"
        class="reseller-printers-table"
        :items-per-page="-1"
        hide-default-footer
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
          <v-container class="action-btn-container">
            <v-btn
              small
              icon
              @click="upsertPrinter(item)"
              :disabled="disabled || loadingDelete"
              :data-test-id="'printer_' + item.id + '_editBtn'"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              @click="deletePrinter(item)"
              :loading="loadingDelete"
              :disabled="disabled || loadingDelete"
              :data-test-id="'printer_' + item.id + '_deleteBtn'"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
    <!-- CREATE PRINTER DIALOG -->
    <v-dialog
      persistent
      v-model="printerDialog"
      width="500px"
      v-if="printerDialog"
    >
      <Printer
        :reseller="reseller"
        :cashdesk="cashdesk"
        :input-printer="selectedPrinter"
        @close="closePrinterDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Printer from "./Printer";

export default {
  props: {
    cashdesk: {
      required: true,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    reseller: {
      required: true,
      type: Object,
    },
  },

  components: {
    Printer,
  },

  data() {
    return {
      selectedPrinter: {},
      printerDialog: false,
      printers: [],
      loadingDelete: false,
      runningAction: false,
    };
  },

  async mounted() {
    await this.loadPrinters();
  },

  methods: {
    async loadPrinters() {
      if (this.reseller.id) {
        this.runningAction = true;
        //load the resellers printers and categorize it into billing and shipping printer
        this.printers =
          await this.$store.$b2bApi.b2bResellerApi.getCashdeskPrinters(
            this.reseller.domain,
            this.reseller.id,
            this.cashdesk.id
          );
        this.runningAction = false;
      }
    },

    upsertPrinter(printer) {
      this.printerDialog = true;
      if (printer) this.selectedPrinter = printer;
      else this.selectedPrinter = {};
    },

    async deletePrinter(printer) {
      if (
        await this.$confirm(
          "Delete printer?",
          "Are you sure you want to delete printer " +
            printer.name +
            " (" +
            printer.type +
            ")" +
            "?"
        )
      ) {
        this.loadingDelete = true;
        try {
          await this.$store.$b2bApi.b2bResellerApi.deleteCashdeskPrinter(
            this.reseller.domain,
            this.reseller.id,
            this.cashdesk.id,
            printer
          );
        } finally {
          this.loadingDelete = false;
          this.closePrinterDialog(true);
        }
      }
    },

    closePrinterDialog(reload) {
      this.printerDialog = false;
      this.selectedPrinter = {};
      if (reload) this.loadPrinters();
    },
  },

  computed: {
    printerHeaders() {
      return [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Host", value: "config.host" },
        { text: "Port", value: "config.port" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.reseller-printer-container > .reseller-printers {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.action-btn-container {
  padding: 0;
  display: flex;
}
</style>