var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"reseller-user-container",attrs:{"fluid":"","fill-height":"","data-test-id":"UsersList"}},[_c('v-card',{staticClass:"reseller-users",attrs:{"flat":"","id":"users"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-group")]),_c('span',[_vm._v("Users")]),_c('v-spacer'),_c('v-switch',{attrs:{"data-test-id":"userDeletedSwitch","label":"Show deleted","color":"primary","disabled":_vm.runningAction},on:{"change":_vm.loadUsers},model:{value:(_vm.showDeleted),callback:function ($$v) {_vm.showDeleted=$$v},expression:"showDeleted"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-btn',{attrs:{"color":"primary","data-test-id":"addUserBtn","disabled":_vm.disabled || _vm.runningAction},on:{"click":function($event){return _vm.upsertUser()}}},[_vm._v(" New User ")])],1),_c('v-data-table',{staticClass:"reseller-users-table",attrs:{"dense":"","sort-by":"preferred","sort-desc":true,"items":_vm.users,"items-per-page":5,"loading":_vm.runningAction,"headers":_vm.userHeaders,"item-class":function (item) { return (item.deleted ? 'deleted' : ''); }},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"action-btn-container"},[_c('a',{attrs:{"href":_vm.$router.resolve({
                name: 'customerDetail',
                params: {
                  domain: _vm.reseller.domain,
                  customer: item.id,
                },
              }).href,"target":"_blank"}},[_vm._v(" "+_vm._s(item.id)+" ")])])]}},{key:"item.roles",fn:function(ref){
              var item = ref.item;
return [_c('v-container',{staticClass:"role-container"},[_vm._v(" "+_vm._s(item.roles.join()))])]}},(_vm.showDeleted)?{key:"item.deleted",fn:function(ref){
              var item = ref.item;
return [_c('v-container',[(item.deleted)?_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close-circle-outline')}}):_vm._e()],1)]}}:null,{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-container',{staticClass:"action-btn-container"},[(!item.deleted)?_c('v-btn',{attrs:{"small":"","icon":"","title":"Login to cashdesk as this user","disabled":_vm.disabled || _vm.runningAction,"data-test-id":'user_' + item.id + '_loginAsBtn'},on:{"click":function($event){return _vm.loginAs(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-login")])],1):_vm._e(),_c('v-btn',{attrs:{"small":"","icon":"","title":"Edit user","disabled":_vm.disabled || _vm.runningAction,"data-test-id":'user_' + item.id + '_editBtn'},on:{"click":function($event){return _vm.upsertUser(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"small":"","icon":"","title":"Restore user","disabled":_vm.disabled || _vm.runningAction,"data-test-id":'user_' + item.id + '_deleteBtn'},on:{"click":function($event){return _vm.deleteRestoreUser(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.deleted ? "mdi-arrow-u-up-right-bold" : "mdi-delete")+" ")])],1)],1)]}}],null,true)})],1),(_vm.userDialog)?_c('v-dialog',{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.userDialog),callback:function ($$v) {_vm.userDialog=$$v},expression:"userDialog"}},[_c('User',{attrs:{"reseller":_vm.reseller,"input-user":_vm.selectedUser},on:{"close":_vm.closeUserDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }