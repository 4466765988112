var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"reseller-cashdesk-container",attrs:{"fluid":"","fill-height":"","data-test-id":"CashdesksList"}},[_c('v-card',{staticClass:"reseller-cashdesks cashdesk-section",attrs:{"flat":"","id":"cashdesks"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cash-register")]),_c('span',[_vm._v("Cashdesks")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","data-test-id":"addCashdeskBtn","disabled":_vm.disabled || _vm.loadingDelete},on:{"click":_vm.addCashdesk}},[_vm._v(" New Cashdesk ")])],1),_c('v-data-table',{staticClass:"reseller-cashdesks-table",attrs:{"dense":"","sort-by":"preferred","sort-desc":true,"items":_vm.cashdesks,"headers":_vm.cashdeskHeaders,"show-expand":"","expanded":_vm.expanded,"loading":_vm.runningAction,"hide-default-footer":"","items-per-page":-1,"no-data-text":"No cashdesks found","data-test-id":"resellerCashdeskList"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{class:{
            'v-data-table__expanded v-data-table__expanded__row': isExpanded,
          }},[_c('td',{staticClass:"reseller-cashdesk-expand",attrs:{"data-test-id":'reseller-cashdesk-expand' + item.id}},[(isExpanded)?_c('v-btn',{attrs:{"icon":"","loading":_vm.loading[item.id],"data-test-id":'cashdesk_' + item.id + '_hide_btn'},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"icon":"","loading":_vm.loading[item.id],"disabled":_vm.loading[item.id],"data-test-id":'cashdesk_' + item.id + '_expand_btn'},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('td',{staticClass:"reseller-cashdesk-name",attrs:{"data-test-id":'reseller-cashdesk-name' + item.id}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"reseller-cashdesk-key",attrs:{"data-test-id":'reseller-cashdesk-key' + item.id}},[_vm._v(" "+_vm._s(item.key)+" ")]),_c('td',{staticClass:"reseller-cashdesk-action",attrs:{"data-test-id":'reseller-cashdesk-action' + item.id}},[_c('v-btn',{attrs:{"small":"","icon":"","disabled":_vm.disabled || _vm.loadingDelete,"loading":_vm.loadingDelete,"data-test-id":'cashdesk_' + item.id + '_deleteBtn'},on:{"click":function($event){return _vm.deleteCashdesk(item)}}},[(!_vm.loadingDelete)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")]):_vm._e()],1)],1)])]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{staticClass:"v-data-table__expanded v-data-table__expanded__content"},[_c('td',{attrs:{"colspan":_vm.cashdeskHeaders.length + 1}},[_c('v-container',{attrs:{"data-test-id":'cashdesk_' + item.id + '_detail'}},[_c('Cashdesk',{attrs:{"reseller":_vm.reseller,"input-cashdesk":item,"disabled":_vm.disabled || _vm.loadingDelete,"dimension-skipass":_vm.dimensionSkipass},on:{"refresh":function($event){return _vm.loadCashdesks()}}}),_c('PrintersList',{attrs:{"reseller":_vm.reseller,"cashdesk":item,"disabled":_vm.disabled || _vm.loadingDelete,"data-test-id":"resellerCashdeskPrintersList"}})],1)],1)])]}}])})],1),(_vm.cashdeskDialog)?_c('v-dialog',{attrs:{"persistent":"","width":"500px"},model:{value:(_vm.cashdeskDialog),callback:function ($$v) {_vm.cashdeskDialog=$$v},expression:"cashdeskDialog"}},[_c('Cashdesk',{attrs:{"reseller":_vm.reseller,"input-cashdesk":{},"data-test-id":"cashdeskDialog"},on:{"close":_vm.closeCashdeskDialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }